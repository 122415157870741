<script setup>
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore, useEventStore, useModalStore } from '../store'
import { atcb_action } from "add-to-calendar-button"
import moment from 'moment'
import 'moment/dist/locale/nl'
import { capitalizeFirstLetter, isAtLeast1HourAway, getYearFromDate } from '../helpers/functions'
import { useRouter } from 'vue-router'
import Modal from '../components/Modal.vue'

const authStore = useAuthStore()
const modalStore = useModalStore()
const eventStore = useEventStore()

const { user: authUser } = storeToRefs(authStore)
const router = useRouter();

// Define your component's data properties using ref or reactive
const showHistory = ref(false);
const menuDropdown = ref(false);
const hideUnbooked = ref(false);

// Computed properties
const upcomingTrainingsCount = computed(() => authStore.sortedTimeslotsCount.upcoming)
const pastTrainingsCount = computed(() => authStore.sortedTimeslotsCount.past)

const calendarConfig = computed(() => {
  const locationId = Object.keys(authStore.user.locations)[0]; // Get the first location ID
  const location = authStore.user.locations[locationId];

  return {
    name: "fit20 Training",
    description: "",
    timeZone: "Europe/Amsterdam",
    location: location ? `fit20 ${location.name}, ${location.address}, ${location.zip}, ${location.city}` : "",
    options: ['Apple', 'Google', 'iCal', 'Outlook.com'],
  };
})

// Methods
function goToWeek(weekNumber, yearNumber) {
  router.push({ path: '/calendar', query: { week: weekNumber, year: yearNumber } })
}

const addToCalendar = (training) => {
  var startDate = moment(training.start).format('YYYY-MM-DD')
  var startTime = moment(training.start).format('LT')
  var endTime = moment(training.end).format('LT')
  // console.log(startDate, '-', startTime, '-', endTime)
  const fullCalendarConfig = {
    name: calendarConfig.value.name,
    description: calendarConfig.value.description,
    timeZone: calendarConfig.value.timeZone,
    location: calendarConfig.value.location,
    options: calendarConfig.value.options,
    startDate: startDate,
    startTime: startTime,
    endTime: endTime,
  }
  // console.log(fullCalendarConfig)
  atcb_action(fullCalendarConfig)
}

const userHasCreditForWeek = (weekNumber) => {
  try {
    var nextWeek = parseInt(weekNumber, 10);
    nextWeek = nextWeek - 1;
    return authUser.value.credits?.available?.includes(weekNumber) ||
      authUser.value.credits?.available?.includes(nextWeek.toString());
  } catch (error) {
    console.error("Error in userHasCreditForWeek:", error);
    return false;
  }
}

const userUsedCreditForWeek = (weekNumber) => {
  var week = parseInt(weekNumber, 10);
  if (authUser.value.credits?.used?.includes(week) || authUser.value.credits?.used?.includes(week.toString())) {
    return true;
  }
  return false;
}

const handleEventClick = (clickInfo) => {
  console.log(clickInfo);
  var eventMembers = clickInfo.extendedProps.users;
  var userId = authUser.value.id;
  if (eventMembers.includes(userId)) {
    eventStore.removeEvent(clickInfo);
  } else {
    eventStore.selectEvent(clickInfo);
  }
}

const forceRemoveEvent = () => {
  eventStore.forceRemove = true;
  removeEvent();
}

const removeEvent = () => {
  eventStore.updateEvent().then((result) => {
    console.log('removeEvent finished with result:', result);
  }).catch((error) => {
    console.log('removeEvent failed with error:', error);
    // Handle the error here
  });
}

const handleUnbookAllEvents = async () => {
  await eventStore.unbookAllEvents();
  menuDropdown.value = false;
}
</script>

<template>
  <main>
    <div class="box">
      <div class="box__header">
        <h6>Welkom, <span class="text-success fw-bold">{{ authUser?.firstname + ' ' + authUser?.lastname }}</span></h6>
        <span class="btn btn-green btn-sm" @click="authStore.refetchUser">Reload <font-awesome-icon
            icon="far fa-refresh"></font-awesome-icon></span>
      </div>
      <div class="box__body">
        <div class="recurring alert alert-info" v-if="authUser?.recurring">
          <h6 class="fw-semibold d-flex align-items-center">Mijn vaste wekelijkse training</h6>
          {{ capitalizeFirstLetter(authUser.recurring.dayname) }} om {{ authUser.recurring.start }} - {{
          authUser.recurring.end }}
          <!-- <button @click="eventStore.stopRecurring" class="btn btn-red fw-bold float-end"
            style="font-size: 0.8rem;">Stop herhalen</button> -->
        </div>
        <h6 class="fw-semibold pb-2 d-flex align-items-center">
          Geplande trainingen
          <span class="badge bg-secondary ms-2">{{ upcomingTrainingsCount }}</span>
          <button @click="menuDropdown = !menuDropdown"
            class="btn-actions btn btn-green ms-auto">Acties<font-awesome-icon icon="fas fa-bars"
              class="ms-2" /></button>
          <div v-if="menuDropdown" class="menu-dropdown">
            <span class="closeDropdown" @click="menuDropdown = !menuDropdown"><font-awesome-icon
                icon="far fa-times"></font-awesome-icon></span>
            <span class="h2 h6">Filters</span>
            <button @click="hideUnbooked = !hideUnbooked">
              <font-awesome-icon v-if="hideUnbooked" icon="fas fa-circle-check"></font-awesome-icon>
              <font-awesome-icon v-if="!hideUnbooked" icon="far fa-circle"></font-awesome-icon>
              Laat alleen trainingen zien
            </button>
            <span class="h2 h6 mt-3">Acties</span>
            <button @click="handleUnbookAllEvents" class="btn btn-red" v-if="upcomingTrainingsCount > 0">Alle training
              afzeggen</button>
          </div>
        </h6>
        <div class="training-overview" style="padding-bottom: 30px;">

          <div v-for="(item, index) in authStore.combinedWeeksAndTrainings" :key="index" class="week mb-2"
            :class="[!item.trainings || !item.trainings.length ? 'no-training' : '', hideUnbooked && (!item.trainings || !item.trainings.length) ? 'hide-no-training' : '', index === 0 ? 'first' : '']">
            <template v-if="!item.trainings || !item.trainings.length">
              <span v-if="!userUsedCreditForWeek(item.week)" @click="goToWeek(item.week, item.year)"
                class="date text-center py-2">Boek training voor week {{ item.week }}</span>
              <span v-if="userUsedCreditForWeek(item.week)" class="date text-center py-2">Geen training beschikbaar voor
                week {{ item.week }}</span>
            </template>
            <template v-else>
              <span v-if="index == 0" class="currentWeek">Huidige week</span>
              <div v-for="(training, tIndex) in item.trainings" :key="tIndex" class="training">
                <span class="date" @click="handleEventClick(training)">
                  {{ capitalizeFirstLetter(moment(training.start).format('ddd D MMMM YYYY')) }}
                  <span class="timespan">
                    <font-awesome-icon icon="far fa-clock" /> {{ moment(training.start).format('LT') }} - {{
          moment(training.end).format('LT') }}
                  </span>
                </span>
                <span class="badge bg-secondary ms-auto me-2">Week {{ training.extendedProps?.week }}</span>
                <span class="actions">
                  <button @click="addToCalendar(training)" class="btn btn-green"><font-awesome-icon
                      icon="far fa-calendar-circle-plus" /></button>
                </span>
                <div class="spacer" v-if="tIndex < item.trainings.length - 1"></div>
                <!-- Add spacer div if it's not the last training -->
              </div>
              <template v-for="(training, tIndex) in item.trainings" :key="tIndex">
                <div @click="goToWeek(training.extendedProps?.week, getYearFromDate(training.start))"
                  class="training credit" v-if="tIndex === 0 && userHasCreditForWeek(training.extendedProps?.week)">
                  Inhaaltraining beschikbaar</div>
              </template>
            </template>
          </div>


        </div>

        <div class="history" style="padding-bottom: 50px;">
          <h6 @click="showHistory = !showHistory" class="fw-semibold pb-2 d-flex align-items-center">
            Training geschiedenis
            <span class="badge bg-secondary ms-2">{{ pastTrainingsCount }}</span>
            <span class="btn btn-green ms-auto">
              <font-awesome-icon v-if="showHistory" icon="fas fa-eye-slash" />
              <font-awesome-icon v-else icon="fas fa-eye" />
            </span>
          </h6>
          <div v-if="showHistory" class="training-overview" style="padding-bottom: 50px;">
            <div v-for="(item, index) in authStore.sortedTimeslots.past" :key="index" class="training mb-2">
              <span class="date">{{ capitalizeFirstLetter(moment(item.start).format('ddd D MMMM YYYY')) }} - {{
          moment(item.start).format('LT') }} - {{ moment(item.end).format('LT') }}</span>
              <span class="actions">
                <span class="badge bg-secondary ms-2">Week {{ item?.extendedProps?.week }}</span>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
  <Modal :id="'removeEvent'" title="" v-if="modalStore.isModalVisible('removeEvent')">
    <div>
      <div class="row g-3">
        <div class="col">
          <span class="time h4 d-block">
            {{ capitalizeFirstLetter(moment(eventStore.eventInfo.event.start).format('dddd, D MMMM, YYYY')) }}
          </span>
          <span class="timeslot d-block">
            Tijdslot: {{ moment(eventStore.eventInfo.event.start).format('LT') }} - {{
          moment(eventStore.eventInfo.event.end).format('LT') }}
          </span>
          <span v-if="!isAtLeast1HourAway(eventStore.eventInfo.event.start)" class="alert alert-danger d-block mt-3">1
            uur van te voren afzeggen is verlopen. Bij annulering krijgt u geen inhaaltraining.</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="button-group">
          <button v-if="isAtLeast1HourAway(eventStore.eventInfo.event.start)" type="button"
            class="btn btn-red-outline me-2" @click="removeEvent">Afzeggen</button>

          <button v-if="!isAtLeast1HourAway(eventStore.eventInfo.event.start)" type="button" class="btn btn-red me-2"
            @click="forceRemoveEvent">Afzeggen zonder inhaaltraining</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
main {
  min-height: 100%;
  width: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: #EDFDF5;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding: 10px 10px;
    // padding: 15px 20px;
    background: #FFFFFF;
    box-shadow: 0 0 12px 5px rgba(50, 183, 104, 0.16);

    h6 {
      margin-bottom: 0;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #EDFDF5;
    padding: 25px 10px;

    h5 {
      margin-bottom: 1em;
    }

    .badge {
      line-height: 1.1;
    }
  }
}

.training-overview {

  .week,
  .training {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 7px 7px 7px 12px;
    box-shadow: 0 0 8px 0 rgba(50, 183, 104, 0.16);

    &.credit {
      padding: 6px 5px !important;
      border: 2px dashed var(--color-green-darker);
      border-radius: 5px !important;
      margin-top: 5px;
      font-weight: 600;
      text-align: center;
      display: block !important;
      background: #EDFDF5;
    }

    .training {
      flex: 1 0 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      box-shadow: none;
      padding: 0;
      border-radius: 0;

      .spacer {
        flex: 1 0 100%;
        display: block;
        border-bottom: 1px solid #eee;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }

    &.first {
      border-top: 2px solid var(--color-green-darker);
    }

    &.no-training {
      background: var(--vt-c-indigo);
      color: $white;

      &.hide-no-training {
        display: none;
      }
    }

    .currentWeek {
      position: absolute;
      top: -12px;
      background: var(--color-green-darker);
      color: white;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      padding: 2px 8px;
      border-radius: 4px;
    }

    .date {
      flex: 1;
      font-size: 1em;
      font-weight: 600;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      // justify-content: center;
      .timespan {
        width: 100%;
        font-size: 0.95em;

        svg {
          color: var(--color-green-darker);
        }
      }
    }
  }
}

h3 {
  font-weight: bold;
}
</style>