<script setup>
import Modal from './components/Modal.vue'; // Import the modal component
import ModalMemberSelect from './components/Modal__MemberSelect.vue';
import MemberSelect from './components/MemberSelect.vue';

import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue'
import { RouterLink, RouterView } from 'vue-router'
import 'add-to-calendar-button'
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { capitalizeFirstLetter, getYearFromDate } from './helpers/functions'
import moment from 'moment'
import 'moment/dist/locale/nl'

import { useAuthStore } from './store/AuthStore';
import { useLocationStore } from './store/LocationStore'
import { useMemberStore } from './store/MemberStore'
import { useModalStore } from './store/ModalStore'
import { useEnhancedInterval } from './composables/useEnhancedUserInterval';

// Checks if user has browser tab open.
// If so it refetches the user every 15 seconds else not.
// useEnhancedInterval();

const authStore = useAuthStore();
const memberStore = useMemberStore()
const locationStore = useLocationStore()
const modalStore = useModalStore()

let cleanupInterval = null;

onMounted(() => {
  if (authStore.user) {
    if (authStore.user.usertype === 'admin' || 
        authStore.user.usertype === 'support' || 
        authStore.user.usertype === 'trainer') {
      console.log('Starting auto refresh for admin/support/trainer');
      authStore.startAutoRefresh();
    } else {
      console.log('Starting enhanced interval for regular user');
      cleanupInterval = useEnhancedInterval();
    }
  }
});

onBeforeUnmount(() => {
  if (authStore.user) {
    if (authStore.user.usertype === 'admin' || 
        authStore.user.usertype === 'support' || 
        authStore.user.usertype === 'trainer') {
      console.log('Cleaning up auto refresh');
      authStore.stopAutoRefresh();
    } else if (cleanupInterval) {
      console.log('Cleaning up enhanced interval');
      cleanupInterval();
    }
  }
});

// Watch voor user changes
watch(() => authStore.user, (newUser, oldUser) => {
  // Cleanup oude interval/refresh
  if (oldUser) {
    if (oldUser.usertype === 'admin' || 
        oldUser.usertype === 'support' || 
        oldUser.usertype === 'trainer') {
      console.log('Stopping auto refresh for previous user');
      authStore.stopAutoRefresh();
    } else if (cleanupInterval) {
      console.log('Stopping enhanced interval for previous user');
      cleanupInterval();
      cleanupInterval = null;
    }
  }

  // Start nieuwe interval/refresh als er een user is
  if (newUser) {
    if (newUser.usertype === 'admin' || 
        newUser.usertype === 'support' || 
        newUser.usertype === 'trainer') {
      console.log('Starting auto refresh for new admin/support/trainer user');
      authStore.startAutoRefresh();
    } else {
      console.log('Starting enhanced interval for new regular user');
      cleanupInterval = useEnhancedInterval();
    }
  }
}, { immediate: true });

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();
const isCalendarRoute = computed(() => {
  // Check if the current route is named 'calendar'
  const isCalendar = route.name === 'calendar';

  // Check if one of the ancestor routes is named 'location'
  const isChildOfLocation = route.matched.some(routeRecord => routeRecord.name === 'location');

  // Return true only if both conditions are met
  return isCalendar && isChildOfLocation;
});

const memberSchema = Yup.object().shape({
  firstname: Yup.string().required('Voornaam is verplicht'),
  lastname: Yup.string().required('Achternaam is verplicht'),
  email: Yup.string().required('E-mailadres is verplicht').email(),
  phone: Yup.string(),
  location: Yup.string().required('Locatie keuze is verplicht'),
  membership: Yup.string().required('Abonnement keuze is verplicht'),
});

function onSubmitNewMember(values, { setErrors }) {
  return memberStore.addMember(values)
    .then(newMember => {
      console.log('Member added successfully:', newMember);
      console.log('Now adding new member to location');
      locationStore.addMemberToLocation(newMember);
      // You can perform any additional actions here, such as displaying a success message
    })
    .catch(error => {
      console.error('Error adding member:', error);
      setErrors({ apiError: error });
    });
}

function avatarLetter(firstname) {
  return firstname.charAt(0).toUpperCase();
}

const canSelectLocations = computed(() => {
  const authStore = useAuthStore();
  if (authStore.user && Object.keys(authStore.user).length > 0 && Object.keys(authStore.user.locations).length >= 1) {
    return false;
  } else {
    return true;
  }
});

// if (authStore.user && authStore.user.usertype !== 'admin' && authStore.user.usertype != 'support' && authStore.user.usertype !== 'trainer') {
//   console.log('Refetch user every 15 seconds');
//   setInterval(() => {
//     authStore.refetchUser();
//   }, 10000);
// }


// On mounted check if user object is existing with token
// onMounted(() => {
//   // console.log('mounted');
//   authStore.verifyUser();
// });

// function deselectMember() {
//   memberStore.selectedMember = {};
//   router.push('/');
// }

// Staat ook in UserHome.vue moet dus opgeschoond worden globaal.
function userHasCreditForWeek(weekNumber) {
  // try {
  //   const memberStore = useMemberStore();
  //   var nextWeek = parseInt(weekNumber, 10);
  //   nextWeek = nextWeek - 1;
  //   return memberStore.selectedMember.credits?.available?.includes(weekNumber) || 
  //           memberStore.selectedMember.credits?.available?.includes(nextWeek.toString());
  // } catch (error) {
  //   console.error("Error in userHasCreditForWeek:", error);
  //   return false;
  // }
}



// Watch selectedLocation
// watch(() => locationStore.selectedLocation, async (newVal, oldVal) => {
//   console.log('watcher trigger for selectedLocation');
//   if(!locationStore.selectedLocation?.members) {
//     console.log('No members found in selected location so fetching location members');
//     await locationStore.fetchLocationMembers();
//   }
//   if(oldVal == null) {
//     router.push('/location/' + locationStore.selectedLocation.id + '/calendar');
//   }
//   if(newVal == null) {
//     memberStore.selectedMember = null; // Reset the selected member
//     memberSelect.value?.$forceUpdate(); // Force re-render of vue-select
//     router.push('/');
//   }
// });
const memberSelect = ref(null);

// watch(() => locationStore.selectedLocation, (newVal, oldVal) => {
//   if (newVal !== oldVal) {
//     memberStore.selectedMember = null; // Reset the selected member
//     memberSelect.value?.$forceUpdate(); // Force re-render of vue-select
//   }
// });

// Watch selectedMember
// watch(() => memberStore.selectedMember, async (newVal, oldVal) => {
//   if(newVal !== oldVal) {
//     // await memberStore.selectMember(memberStore.selectedMember);
//   }
//   if (newVal && Object.keys(newVal).length > 0 && newVal !== oldVal) {
//     // await memberStore.selectMember(memberStore.selectedMember);
//   }
// });

// Whenever the search value changes, try to find a member that matches the search
//  !! Deze functie zorgde ervoor dat de selectedMember al geupdate werd voordat iemand definitief gefiltered en geklikt had.
watch(
  () => memberStore.search,
  (newVal) => {
    // const matchingMember = locationStore.filteredMembers.find(member => 
    //   memberStore.fullName(member).toLowerCase().includes(newVal.toLowerCase())
    // );
    // if (matchingMember) {
    //   memberStore.selectedMember = matchingMember;
    // }
  }
);


// const handleMemberClear = (newValue) => {
//   console.log('HandleMember');
//   if (!newValue) { // if newValue is null or undefined
//     memberStore.selectedMember = {};
//   }
// };

// Convert the object of objects into an array
const locationsArray = computed(() => {
  return Object.values(locationStore.locations);
});

const handleMemberSelected = (member) => {
  console.log(member);
};

const selectLocation = (location) => {
  const locationId = location ? location.id : null;
  memberStore.selectedMember = null; // Reset the selected member
  locationStore.setCurrentLocation(locationId)
    .then((selectedLocation) => {
      if (selectedLocation) {
        console.log('Location set and members fetched');
        // Route to calendar if not already there
        if (router.currentRoute.value.name !== 'calendar') {
          router.push({
            name: 'calendar',
            params: { id: selectedLocation.id }
          });
        }
      } else {
        console.log('Location unset');
        // Route to locations page
        router.push({ name: 'locations' });
      }
    })
    .catch(error => {
      console.error('Error setting location:', error);
      // Handle the error (e.g., show a notification to the user)
    });
};

const hideUnbooked = ref(false);

function toggleAdminMenu() {
  return authStore.hideAdminMenu = !authStore.hideAdminMenu;
}
const refreshCalendar = () => {
  authStore.triggerCalendarRefresh();
};

function goToWeek(weekNumber, yearNumber) {
  const locationStore = useLocationStore();
  const locationId = locationStore.selectedLocation.id;

  console.log('Go to week:', weekNumber, yearNumber);

  if (!locationId) {
    console.error('No location selected');
    return;
  }

  router.push({
    path: `/location/${locationId}/calendar`,
    query: { week: weekNumber, year: yearNumber }
  });
}

</script>

<template>
  <div class="admin_app"
    v-if="authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support' || authStore.user?.usertype == 'trainer')"
    :class="authStore.user.usertype, { hideMenu: authStore.hideAdminMenu }">
    <div class="admin_app-sidebar">
      <div class="logo px-8 flex-1 justify-content-between">
        <router-link to="/" v-slot="{ href, navigate }" custom>
          <a @click="navigate" :href="href">
            <img src="/favicon-32x32.png" alt="">
            <span>Studio Tijd</span>
          </a>
        </router-link>
        <button @click="toggleAdminMenu()" class="btn btn-green"><font-awesome-icon icon="fas fa-bars" /></button>
      </div>
      <div v-if="!authStore.hideAdminMenu" class="separator d-none d-lg-block"></div>
      <div v-if="!authStore.hideAdminMenu" class="selector px-8 my-4">
        <v-select id="selectedLocation" v-model="locationStore.selectedLocation" placeholder="Kies een locatie"
          @option:selected="selectLocation" :options="locationsArray" label="name" :disabled="canSelectLocations">
          <template #selected-option="{ name }">
            <div style="display: flex; align-items: center;">
              <font-awesome-icon icon="fas fa-building" /><strong>{{ name }}</strong>
            </div>
          </template>
        </v-select>
        <ul v-if="locationStore.selectedLocation && Object.keys(locationStore.selectedLocation).length > 0"
          class="nav-list mb-0">
          <router-link :to="{ name: 'calendar', params: { id: locationStore.selectedLocation.id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom>
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="far fa-calendar-alt" />
                Kalender
              </a></li>
          </router-link>
          <router-link :to="{ name: 'recurring', params: { id: locationStore.selectedLocation.id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom>
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="far fa-calendar-alt" />
                Vaste trainingen
              </a></li>
          </router-link>
          <router-link :to="{ name: 'members', params: { id: locationStore.selectedLocation.id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom v-if="authStore.isAdmin">
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="fas fa-users" />
                Leden
              </a></li>
          </router-link>
          <!-- <router-link :to="{ name: 'settings', params: { id: locationStore.selectedLocation.id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom v-if="authStore.isAdmin">
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="far fa-clock" />
                Openingstijden</a></li>
          </router-link>
          <router-link :to="{ name: 'profile', params: { id: locationStore.selectedLocation.id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom v-if="authStore.isAdmin">
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="far fa-building" />
                Contactgegevens</a></li>
          </router-link> -->
        </ul>
      </div>
      <div v-if="!authStore.hideAdminMenu" class="separator d-none d-lg-block"></div>
      <div
        v-if="!authStore.hideAdminMenu && locationStore.selectedLocation && Object.keys(locationStore.selectedLocation).length > 0 && (authStore.user.usertype == 'admin' || authStore.user.usertype == 'support')"
        class="selector px-8 my-4">
        <MemberSelect @member-selected="handleMemberSelected" ref="memberSelect" />
        <span class="badge duo-badge" v-if="memberStore.selectedMember?.duo">
          <strong>Duo partner:</strong> {{ memberStore.findMemberDataById(memberStore.selectedMember?.duo)?.fullname ||
      'None'
          }}
        </span>
        <ul v-if="memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0"
          class="nav-list mb-0">
          <router-link :to="{ name: 'calendar', params: { id: memberStore.selectedMember.location_id } }"
            v-slot="{ href, navigate, isActive, isExactActive }" custom v-if="authStore.user.usertype == 'admin'">
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href"
                @click="navigate">
                <font-awesome-icon icon="far fa-calendar-alt" />
                Kalender
              </a></li>
          </router-link>
          <li v-if="authStore.user.usertype == 'admin'">
            <a @click="memberStore.selectMember(memberStore.selectedMember, true)">
              <font-awesome-icon icon="far fa-user" />
              Profiel
            </a>
          </li>
        </ul>
        <ul v-if="memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0" class="nav-list">
          <li class="text-center pb-2"><strong>Aankomende traingen</strong></li>
        </ul>
        <div class="training-overview"
          v-if="memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0">
          <div v-for="(item, index) in memberStore.combinedWeeksAndTrainings" :key="index" class="week mb-2"
            :class="[!item.trainings || !item.trainings.length ? 'no-training' : '', hideUnbooked && (!item.trainings || !item.trainings.length) ? 'hide-no-training' : '', index === 0 ? 'first' : '']">
            <template v-if="!item.trainings || !item.trainings.length">
              <span @click="goToWeek(item.week, item.year)" class="date text-center py-2">Boek training voor week {{
      item.week }}</span>
            </template>
            <template v-else>
              <span v-if="index == 0" class="currentWeek">Huidige week</span>
              <div v-for="(training, tIndex) in item.trainings" :key="tIndex" class="training">
                <!-- <span class="date" @click="handleEventClick(training)"> -->
                <span class="date" @click="goToWeek(training.extendedProps?.week, getYearFromDate(training.start))">
                  {{ capitalizeFirstLetter(moment(training.start).format('ddd D MMMM YYYY')) }}
                  <span class="timespan">
                    <font-awesome-icon icon="far fa-clock" /> {{ moment(training.start).format('LT') }} - {{
      moment(training.end).format('LT') }}
                  </span>
                </span>
                <span class="badge bg-secondary ms-auto me-2">Week {{ training.extendedProps?.week }}</span>
                <div class="spacer" v-if="tIndex < item.trainings.length - 1"></div>
                <!-- Add spacer div if it's not the last training -->
              </div>
              <template v-for="(training, tIndex) in item.trainings" :key="tIndex">
                <div @click="goToWeek(training.extendedProps?.week, getYearFromDate(training.start))"
                  class="training credit" v-if="tIndex === 0 && userHasCreditForWeek(training.extendedProps?.week)">
                  Inhaaltraining beschikbaar</div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!authStore.hideAdminMenu" class="separator d-none d-lg-block"></div>
      <div v-if="!authStore.hideAdminMenu" class="selector px-8 my-4">
        <button class="btn btn-red btn-sm fw-bold" @click="authStore.logout()">Uitloggen</button>
      </div>
      <!-- <div class="nav px-8 my-4">
        <ul class="nav flex-column list-unstyled">
          <router-link
            to="/locations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
              class="nav-item"
            >
              <a :href="href" @click="navigate" class="nav-link">
                <font-awesome-icon icon="fas fa-home" class="pe-1" />
                Locaties
              </a>
            </li>
          </router-link>
          <router-link
            to="/members"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
              class="nav-item"
            >
              <a :href="href" @click="navigate" class="nav-link">
                <font-awesome-icon icon="fas fa-users" class="pe-1" />
                Leden
              </a>
            </li>
          </router-link>
          <li class="nav-item">
            <a class="nav-link" @click="memberStore.openNewMemberForm">
              <font-awesome-icon icon="fas fa-user-plus" class="pe-1" />
              Nieuw lid
            </a>
          </li>
        </ul>
      </div> -->
    </div>
    <div class="admin_app-main">
      <div class="user-profile">
        <span class="text-mite">Ingelogd als: {{ authStore.user.firstname + ' ' + authStore.user.lastname }}</span>
        <div class="selector">
          <!-- <button class="btn btn-red btn-sm fw-bold" @click="authStore.logout()">Uitloggen</button> -->
          <!-- <button class="btn btn-green btn-sm me-2" @click="authStore.refetchUser()">Refresh User<font-awesome-icon
              icon="far fa-refresh"></font-awesome-icon></button>
          <button class="btn btn-green btn-sm me-2" @click="refreshCalendar">Refresh Kalendar<font-awesome-icon
              icon="far fa-refresh"></font-awesome-icon></button> -->
        </div>

      </div>

      <RouterView />
      <Modal :id="'newMember'" :title="'Nieuw lid toevoegen'" v-if="modalStore.isModalVisible('newMember')">
        <div>
          <Form @submit="onSubmitNewMember" :validation-schema="memberSchema" v-slot="{ errors, isSubmitting }">
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <Field name="firstname" type="text" class="form-control" placeholder="Joe"
                    :class="{ 'is-invalid': errors.firstname }" />
                  <label for="firstname">Voornaam</label>
                  <div class="invalid-feedback">{{ errors.firstname }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <Field name="lastname" type="text" class="form-control" placeholder="Doe"
                    :class="{ 'is-invalid': errors.lastname }" />
                  <label for="lastname">Achternaam</label>
                  <div class="invalid-feedback">{{ errors.lastname }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <Field name="email" type="email" class="form-control" placeholder="Doe"
                    :class="{ 'is-invalid': errors.email }" />
                  <label for="email">E-mailadres</label>
                  <div class="invalid-feedback">{{ errors.email }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <Field name="phone" type="text" class="form-control" placeholder="070-12341234"
                    :class="{ 'is-invalid': errors.phone }" />
                  <label for="phone">Telefoonnummer</label>
                  <div class="invalid-feedback">{{ errors.phone }}</div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <h5 class="fw-bold">Locatie</h5>
                <Field name="location" as="select" class="form-select" :class="{ 'is-invalid': errors.location }">
                  <option value="" disabled>Kies een locatie</option>
                  <option v-for="(locationData, index) in locationStore.locations" :key="index"
                    :value="locationData.id">
                    {{ locationData.name }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors.location }}</div>
              </div>
              <div class="col-12 mt-4">
                <h5 class="fw-bold">Abonnement</h5>
                <Field name="membership" as="select" class="form-select" :class="{ 'is-invalid': errors.membership }">
                  <option value="" disabled>Kies een abonnement</option>
                  <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
                    {{ capitalizeFirstLetter(membership) }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors.membership }}</div>
              </div>
              <div class="col-12 mt-4">
                <h5 class="fw-bold">Notificaties</h5>
                <div class="form-check">
                  <Field name="notifications" type="checkbox" class="form-check-input"
                    :class="{ 'is-invalid': errors.notifications }" :value="false" />
                  <label class="form-check-label" for="notifications">Heeft e-mail notificaties aan?</label>
                  <div class="invalid-feedback">{{ errors.notifcations }}</div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <button class="btn btn-primary" :disabled="isSubmitting">
                <span v-show="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
                Opslaan
              </button>
            </div>
            <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
          </Form>
        </div>
      </Modal>

      <ModalMemberSelect></ModalMemberSelect>
    </div>
  </div>
  <div v-if="!authStore.user" class="container-fluid p-0">
    <div class="col-12 d-flex p-0" style="min-height: 100vh;">
      <RouterView />
    </div>
  </div>
  <div v-if="authStore.user && authStore.userLevel === 1" class="container-fluid p-0">
    <div class="col-12 d-flex p-0" style="min-height: 100vh;">
      <RouterView />
    </div>
    <div class="mobile-nav">
      <router-link to="/" v-slot="{ href, navigate, isActive, isExactActive }" custom>
        <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" class="nav-item"
          :href="href" @click="navigate">
          <span>
            <font-awesome-icon icon="far fa-home" />
          </span>
        </span>
      </router-link>
      <router-link :to="'/calendar'" v-slot="{ href, navigate, isActive, isExactActive }" custom>
        <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" class="nav-item"
          :href="href" @click="navigate">
          <span>
            <font-awesome-icon icon="far fa-calendar-alt" />
          </span>
        </span>
      </router-link>
      <router-link to="/profile" v-slot="{ href, navigate, isActive, isExactActive }" custom>
        <span :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']" class="nav-item"
          :href="href" @click="navigate">
          <span>
            <font-awesome-icon icon="far fa-user" />
          </span>
        </span>
      </router-link>
    </div>
  </div>
  <!-- <div v-if="authStore.user && authStore.userLevel == 2" class="container-fluid support p-0">
    <div class="container my-4">
      <div class="row">
        
        <RouterView />
      </div>
    </div>
  </div> -->
</template>

<style lang="scss" scoped>
.selection {
  display: flex;
  text-align: left;
  border: 2px solid #ddd;

  .user {
    display: flex;
    flex-direction: column;
  }

  .h6 {
    margin: 0;
    color: #888;
    font-size: 0.95rem;
  }

  .h4 {
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0;
  }

  .close {
    margin: 4px -22px 0 10px;
    height: 28px;
    width: 28px;
    border-radius: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

#sidebarMenu {

  // box-shadow: none;
  // padding-top: 60px;
  // background: white !important;
  .nav-item {
    margin-top: 6px;

    .nav-link {
      font-size: 0.95rem;
      border-radius: 8px;
      // border: 2px solid white;
      font-weight: 600;
      color: #666;
      // background: #eee;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      svg {
        color: #666;
        margin-right: 6px;
      }

      &:hover {
        background: #333;
        color: white;

        svg {
          color: var(--color-green);
        }
      }
    }

  }

  .router-link-active {
    .nav-link {
      background: #333;
      color: white;

      svg {
        color: var(--color-green);
      }
    }
  }

  ul li {
    color: var(--color-green-darker);

    a {
      color: var(--color-green-darker);
    }
  }
}

.mtraining {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .date {
    font-weight: bold;
  }
}

.mobile-nav {
  position: fixed;
  display: flex;
  z-index: 999999;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 60px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #32B768;
}

.mobile-nav .nav-item {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: white;
}

.mobile-nav .nav-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: transparent;
}

.mobile-nav .router-link-active span {
  background: var(--color-green);
}

.duo-badge {
  font-size: 13px;
  margin-top: 10px;
  color: #000;
  font-weight: 500;
  background-color: #DDF8FC;
}
</style>
